<template>
  <div class="questions-list">
    <b-row>
      <b-col lg="3">
        <section class="modify-paid-trial-period">
          <ValidationObserver ref="addTrailPeriodObserver">
            <b-card no-body class="trial-period-card p-1">
              <b-card-body class="p-0">
                  <div class="modify-paid-trial-period-card__period-input-container">
                       <ek-input-text :rules="[
                      {
                        type: 'required',
                        message: 'تحديد الفترة الزمنية المدفوعة مطلوب',
                      },
                    ]"
                                     label="تحديد الفترة الزمنية المدفوعة"
                                     type="number"
                                     v-model="trialPeriodDto.trialPeriod"
                                     name="تحديد الفترة الزمنية المدفوعة">
                      </ek-input-text>
                      <div class="modify-paid-trial-period-card__day-place-holder">يوم</div>
                  </div>
                <div class="d-flex justify-content-end">
                  <b-button variant="outline-primary" @click="modifyTrailPeriod">
                    تحديد
                  </b-button>
                </div>
              </b-card-body>
            </b-card>
          </ValidationObserver>
        </section>

        <section class="commercial-activites">
          <ValidationObserver ref="addCommercialActivityObserver">
            <b-card no-body class="commercial-activites-card">
              <b-card-header class="commercial-activites-card__header border-bottom">
                <div class="commercial-activites-card__title">النشاطات التجارية</div>
                <div class="commercial-activites-card__add-button">
                  <ek-modal
                    btnText="إضافة"
                    noFill
                    noHeader
                    size="sm"
                    noFloatBtn
                    @add="addCurrentCommercialActivity"
                    @close="resetActivityDto"
                    ref="commercialDialg"
                  >
                    <ek-input-text
                      :rules="[
                        {
                          type: 'required',
                          message: 'اسم النشاط التجاري مطلوب',
                        },
                      ]"
                      label="اسم النشاط التجاري"
                      v-model="commercialActivityDto.name"
                      name="اسم النشاط التجاري مطلوب"
                    >
                    </ek-input-text>
                  </ek-modal>
                </div>
              </b-card-header>
              <b-card-body class="p-0">
                <div
                  v-for="activity in filterdCommercialActivites"
                  :key="activity.id"
                  class="d-flex justify-content-between align-items-center border-bottom p-2"
                >
                  <div>{{ activity.name }}</div>
                  <b-button
                    @click="deleteCurrentActivity(activity.id)"
                    variant="flat-secondary"
                    class="commercial-activites-card__trash-button btn-icon btn-sm rounded-circle"
                  >
                    <unicon width="15" name="trash-alt" />
                  </b-button>
                </div>
              </b-card-body>
            </b-card>
          </ValidationObserver>
        </section>

        <b-col cols="12" class="d-flex justify-content-center mb-3">
          <ek-pagination
            :items="commercialActivitiesList"
            v-model="filterdCommercialActivites"
          />
        </b-col>
      </b-col>
      <b-col lg="9" md="12">
        <b-card no-body class="questions-list-card">
          <div class="questions-list-card__header p-2">
            <b-row align-v="center">
              <b-col cols="7">
                <div class="questions-list-card__common-questions-title ml-3">
                  الأسئلة الشائعة
                  {{ "(" + questionsCount + ")" }}
                </div>
              </b-col>
              <b-col
                cols="3"
                class="questions-list-card__add-date-title d-flex justify-content-center"
              >
                تاريخ الإضافة
              </b-col>
              <b-col cols="3"> </b-col>
            </b-row>
          </div>
          <div class="questions-list-card__body">
            <div
              class="questions-list-card__question-answer-container"
              v-for="(question, index) in filterdQuestions"
              :key="question.id"
            >
              <div class="questions-list-card__question-container">
                <b-row align-v="center">
                  <b-col cols="7" sm="7" class="d-flex">
                    <div
                      class="questions-list-card__question-circle bg-primary text-white mr-1"
                    >
                      {{ index + 1 }}
                    </div>
                    <div class="questions-list-card__question d-flex align-items-center">
                      {{ question.question }}
                    </div>
                    <b-button
                      @click="toggleAnswer(question.id)"
                      variant="flat-secondary"
                      class="questions-list-card__arrow-button btn-icon btn-sm rounded-circle align-self-center"
                    >
                      <unicon
                        width="15"
                        name="angle-down"
                        :class="{
                          flipped: question.isActive,
                        }"
                      />
                    </b-button>
                  </b-col>
                  <b-col
                    cols="3"
                    sm="3"
                    class="d-flex justify-content-center d-flex align-items-center"
                  >
                    {{ new Date(question.date).toLocaleDateString("en-UK") }}</b-col
                  >
                  <b-col
                    cols="2"
                    sm="2"
                    class="d-flex justify-content-center align-items-center"
                  >
                    <b-button
                      variant="flat-secondary"
                      class="questions-list-card__trash-button btn-icon btn-sm rounded-circle"
                      @click="navigateToQuestionDetails(question.id)"
                    >
                      <unicon width="15" name="edit"></unicon>
                    </b-button>
                    <b-button
                      variant="flat-secondary"
                      class="questions-list-card__edit-button btn-icon btn-sm rounded-circle"
                      @click="deleteCurrentQuestion(question.id)"
                    >
                      <unicon width="15" name="trash-alt"></unicon>
                    </b-button>
                  </b-col>
                </b-row>
              </div>

              <div v-if="question.isActive" class="questions-list-card__answer-container">
                <b-row>
                  <b-col cols="7">
                    <div class="questions-list-card__asnwer-text ml-3">
                      {{ question.answer }}
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
        <b-col cols="12" class="d-flex justify-content-center mb-3">
          <ek-pagination :items="questionsList" v-model="filterdQuestions" />
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters(["questionsList", "questionsCount", "commercialActivitiesList"]),
    ...mapState({
      questions: (state) => state.settings.questions,
        trialPeriodDto: (state) => state.settings.trialPeriodDto,
      commercialActivityDto: (state) => state.commercialActivities.commercialActivityDto,
    }),
  },
  created() {
    this.getSettings();
  },
  data: () => ({
    columns: [
      {
        label: "الأسئلة الشائعة",
        field: "question",
      },
      {
        label: "تاريخ الإضافة",
        field: "date",
      },

      {
        label: "تفاصيل",
        field: "details",
        sortable: false,
      },
    ],
    filterdQuestions: [],
    filterdCommercialActivites: [],
  }),
  methods: {
    ...mapActions([
      "getSettings",
      "deleteCommercialActivity",
      "deleteQuestion",
      "addCommercialActivity",
      "addTrailPeriod",
    ]),
    ...mapMutations(["Toggle_Settings_Question_Status", "Reset_Commercial_Activity"]),
    modifyTrailPeriod() {
      this.$refs.addTrailPeriodObserver.validate().then((success) => {
        if (success) {
            this.addTrailPeriod({ trialPeriod: this.trialPeriodDto.trialPeriod});
        }
      });
    },
    resetActivityDto() {
      this.Reset_Commercial_Activity();
    },
    navigateToQuestionDetails(id) {
      this.$router.push(`/admin/settings/questions/${id}`);
    },
    addCurrentCommercialActivity() {
      this.$refs.addCommercialActivityObserver.validate().then((success) => {
        if (success) {
          this.addCommercialActivity(this.commercialActivityDto);
          this.$refs.commercialDialg.close();
        }
      });
    },
    deleteCurrentQuestion(id) {
      this.deleteQuestion(id);
    },
    toggleAnswer(id) {
      this.Toggle_Settings_Question_Status(id);
    },
    deleteCurrentActivity(id) {
      this.deleteCommercialActivity(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.modify-paid-trial-period-card {
  &__period-input-container {
    position: relative;

    &::v-deep {
      input {
        padding-right: 3.4rem !important;
      }
    }
  }

  &__day-place-holder {
    position: absolute;
    top: 3rem;
    right: 1.8rem;
    opacity: 0.5;
  }
}

.commercial-activites-card {
  &__header {
    @media only screen and (max-width: 1080px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__add-button {
    &::v-deep {
      button {
        background-color: white !important;
        color: #007fff !important;
        &:hover {
          background-color: rgba(0, 127, 255, 0.04) !important;
          box-shadow: none !important;
        }
      }
    }
  }

  &__trash-button {
    padding: 2px 6px !important;
  }
}

.questions-list-card {
  &__header {
    border-bottom: 1px solid #ebe9f1;
  }

  &__common-questions-title,
  &__add-date-title {
    font-size: 1.3rem;
  }

  &__question-container {
    padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

    min-height: 50px;
  }

  &__question {
    flex: 1;
  }

  &__question-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  &__trash-button,
  &__edit-button,
  &__arrow-button {
    padding: 2px 6px !important;
  }

  &__answer-container {
    border-bottom: 1px solid #ebe9f1;
    padding: 1.5rem;
  }
}
</style>
